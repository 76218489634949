<template>
  <div class="monitoring">
    <el-container>
      <!-- 左侧树结构 -->
      <!-- <el-collapse-transition></el-collapse-transition> -->
      <el-aside>
        <div class="aside-title">
          <div class="aside-box-one">
            <p>{{carNumForm.totalCarNum}}</p>
            <p>车辆总数（辆）</p>
          </div>
          <div class="aside-box-two">
            <p>{{carNumForm.normalCarNum}}</p>
            <p>在线数量（辆）</p>
          </div>
          <div class="aside-box-three">
            <p>{{ carNumForm.onlineRate }}</p>
            <p>在线率（%）</p>
          </div>
        </div>
        <!-- 搜索框 -->
        <div class="search">
          <el-input v-model.trim="input" placeholder="车牌号码" clearable>
            <el-button slot="append" icon="el-icon-search" @click="search()"></el-button>
          </el-input>
        </div>
        <div class="tree-title">
          <i></i>车辆列表
        </div>
        <!-- 数结构区域 -->
        <div class="checkbox">
          <el-checkbox v-model="checked">只显示在线车辆</el-checkbox>
          <el-tree
            :props="defaultProps"
            :load="loadNode"
            lazy
            @node-click="handleNodeClick"
            accordion
            :highlight-current="true"
          ></el-tree>
        </div>
      </el-aside>
      <!-- 右侧内容区 -->
      <el-main id="mainWindow">
        <!-- 定义地图显示容器 -->
        <div id="mapContainer" v-if="show"></div>
        <!-- 控件提示页面 -->
        <div v-else class="Controls">
          <div class="ControlsText">
            <h1>Web控件未运行</h1>
            <span>请尝试以下办法</span>
            <p>
              1) Web控件未运行&nbsp;
              <a href="https://zyc.hn12320.cn/HATWebCtrlPlugin.exe" download="HATWebCtrlPlugin.exe">安装</a> &nbsp;&nbsp;&nbsp;
              <a @click="refresh" href="#">刷新</a>
            </p>
            <p>
              2) 本机已安装Web控件，请先启动服务后刷线当前页面 &nbsp;&nbsp;&nbsp;
              <a href>启动</a>&nbsp;
              <a href="#" @click="refresh">刷新</a>
            </p>
          </div>
        </div>
        <!-- 监控窗口区域 -->
        <div class="playfather">
          <div id="playWnd" class="playWnd" v-show="jk">
            <button class="closeplayWnd" @click="closeplayWnd()"></button>
          </div>
        </div>
      </el-main>
      <el-dialog :visible.sync="orderDialogFormVisible" class="orderDialog" :modal="false">
        <el-tabs v-model="activeName">
          <el-tab-pane label="车辆信息" name="first">
            <div class="carInfo">
              <p>车牌号：{{this.car.carNum}}</p>
              <p>所属单位：{{this.car.corpName}}</p>
              <p>车速：{{this.gpsForm.speed}}</p>
              <p>方向角：{{this.gpsForm.direction}}</p>
              <p>GPS方向时间：{{}}</p>
              <p>经纬度：</p>
              <p>地理位置：</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="随车人员信息" name="third">
            <div>
              <div class="peoInfo">
                <p v-for="(itemc,idoc) in driver" :key="idoc">驾驶员：{{itemc.name}}</p>
                <p v-for="(itemc,idoc) in driver" :key="idoc">电话:{{itemc.phone}}</p>
              </div>
              <div class="peoInfo">
                <p v-for="(itemn,inur) in doc" :key="inur">随车医生：{{itemn.name}}</p>
                <p v-for="(itemn,inur) in doc" :key="inur">电话:{{itemn.phone}}</p>
              </div>
              <div class="peoInfo">
                <p v-for="(iteme,iescort) in nur" :key="iescort">随车护士：{{iteme.name}}</p>
                <p v-for="(iteme,iescort) in nur" :key="iescort">电话:{{iteme.phone}}</p>
              </div>
              <div class="peoInfo">
                <p v-for="(itemr,iremove) in remove" :key="iremove">搬抬人员：{{itemr.name}}</p>
                <p v-for="(itemr,iremove) in remove" :key="iremove">电话:{{itemr.phone}}</p>
              </div>
              <div class="peoInfo">
                <p v-for="(itemr,iremove) in escort" :key="iremove">护送人员：{{itemr.name}}</p>
                <p v-for="(itemr,iremove) in escort" :key="iremove">电话:{{itemr.phone}}</p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="设备信息" name="fourth">
            <div class="facilityInfo">
              <div>
                <p>车载终端名称：{{}}</p>
                <p>车载SIM卡ID：{{}}</p>
              </div>
              <div>
                <p>车载SIM卡电话号码：{{}}</p>
                <p>车载平板名称：{{}}</p>
              </div>
              <div>
                <p>车载摄像头4远程URL：{{}}</p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="btns">
          <button class="look" @click="preview()">
            <i class="look_icon"></i>
            <p>预览</p>
          </button>
          <!-- <button class="follow" @click="tracking()">
            <i class="follow_icon"></i>
            <p>跟踪</p>
          </button>-->
        </div>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'monitoring',
  components: {
  },
  data () {
    return {
      input: '',
      checked: '',
      defaultProps: {
        label: 'name',
        children: [],
        isLeaf: 'leaf'
      }, // 树结构配置对象
      fromlongitude: 0, // 当前经度,
      fromlatitude: 0, // 当前纬度
      tolongitude: 0, // 目标经度,
      tolatitude: 0, // 目标纬度
      centerlongitude: 28.213754,
      centerlatitude: 112.869051,
      car: {},
      show: true,
      cur: 0,
      clickCarNumHandle: '',
      carNumCur: '',
      orderDialogFormVisible: false,
      form: {},
      activeName: 'first',
      carType: '../../images/carblue.png',
      doc: [],
      nur: [],
      escort: [],
      remove: [],
      driver: [],
      iLastCoverLeft: 0,
      iLastCoverTop: 0,
      iLastCoverRight: 0,
      iLastCoverBottom: 0,
      width: 400,
      height: 600,
      playHeight: 600,
      // 初始化参数
      appKey: '808_RDzSpplC',
      oWebControl: null,
      WebControl: null,
      jk: false,
      phone: '17708498536',
      gpsForm: {

      },
      carNumForm: {

      }
    }
  },
  created () {
    this.detection('HATWebCtrlPlugin')
    this.getCarSum()
    // 回调函数
    window.cb = function cb (ret) {
      // 从结果中取出路线坐标串
      var coors = ret.result.routes[0].polyline; var pl = []
      // 坐标解压（返回的点串坐标，通过前向差分进行压缩，因此需要解压）
      var kr = 1000000
      for (var i = 2; i < coors.length; i++) {
        coors[i] = Number(coors[i - 2]) + Number(coors[i]) / kr
      }
      // 将解压后的坐标生成LatLng数组
      for (var i = 0; i < coors.length; i += 2) {
        pl.push(new TMap.LatLng(coors[i], coors[i + 1]))
      }
      if (window.cur >= 1) {
        window.polylineLayer.setGeometries([{ id: window.polylineLayer.id, paths: pl }])
        window.marker.setGeometries([{
          id: 'start',
          styleId: 'start',
          position: new TMap.LatLng(window.fromlatitude, window.fromlongitude)
        }])
        window.marker.setStyles({
          start: new TMap.MarkerStyle({
            width: 44,
            height: 84,
            anchor: { x: 16, y: 32 },
            faceTo: 'map',
            rotate: window.that.direction,
            src: require('../../images/carblue.png')
          })
        })
        window.infoWindow.setPosition(new TMap.LatLng(window.fromlatitude, window.fromlongitude))
      } else {
        window.display_polyline(pl)// 显示路线
        window.display_mark()
      }
    },
    // 线路样式
    window.display_polyline = function display_polyline (pl) {
      // 创建 MultiPolyline显示折线
      var polylineLayer = new TMap.MultiPolyline({
        id: 'polyline', // 图层唯一标识
        map: window.map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: '#3777FF', // 线填充色
            width: 6, // 折线宽度
            borderWidth: 5, // 边线宽度
            borderColor: '#FFF', // 边线颜色
            lineCap: 'round' // 线端头方式
          })
        },
        // 折线数据定义
        geometries: [
          {
            id: 'pl_1', // 折线唯一标识，删除时使用
            styleId: 'style_blue', // 绑定样式名
            paths: pl
          }
        ]
      })
      window.polylineLayer = polylineLayer
    }
    // this.getInitParam();//获取初始话所需的参数
  },
  beforeMount () {
    // 调用电脑中的插件
  },
  mounted () {
    // this.initPlugin()
    window.addEventListener('keydown', this.keydown)
    // 创建地图
    this.createMap(this.centerlongitude, this.centerlatitude)
    // 画线
    window.initMap = function initMap (flatitude, flongitude, tlatitude, tlongitude) {
      // WebServiceAPI请求URL（驾车路线规划默认会参考实时路况进行计算）
      var url = 'https://apis.map.qq.com/ws/direction/v1/driving/' // 请求路径
      url += '?from=' + flatitude + ',' + flongitude // 起点坐标
      url += '&to=' + tlatitude + ',' + tlongitude // 终点坐标
      url += '&output=jsonp&callback=cb' // 指定JSONP回调函数名，本例为cb
      url += '&key=LDIBZ-UYHWR-D66WD-WLGIT-WU5DJ-TEFZJ' // 开发key，可在控制台自助创建
      // 发起JSONP请求，获取路线规划结果
      window.jsonp_request(url)
    },

    // 浏览器调用WebServiceAPI需要通过Jsonp的方式，此处定义了发送JOSNP请求的函数
    window.jsonp_request = function jsonp_request (url) {
      var script = document.createElement('script')
      script.src = url
      document.body.appendChild(script)
    },
    // 标注点
    window.display_mark = this.display_mark
    //   this.swfHeight = document.getElementById("videoMaincontent1").offsetHeight;
    // this.swfWidth = document.getElementById("videoMaincontent1").offsetWidth;
    // 页面加载时创建播放实例初始化

    // 监听resize事件，使插件窗口尺寸跟随DIV窗口变化
    window.that = this
    // that.initPlugin();
    $(window).resize(() => {
      if (that.oWebControl != null) {
        that.oWebControl.JS_Resize(that.width, that.height)
        that.setWndCover()
      }
    })
    // 监听滚动条scroll事件，使插件窗口跟随浏览器滚动而移动
    $(window).scroll(() => {
      if (that.oWebControl != null) {
        this.oWebControl.JS_Resize(that.width, that.height)
        this.setWndCover()
      }
    })
  },
  beforeDestroy () {
    clearInterval(window.time)
  },
  destroyed () {
    this.closeWindow()
  },
  methods: {
    async search () {
      const { data: res } = await this.$http.post('/pmService/tInfoVehicle/getOnlineCar', {
        screenPar: this.input
      })
      console.log(res)
    },
    // 点击左侧树节点触发
    async handleNodeClick (data, node) {
      if (node.level == 5) {
        console.log(data)
        // ：0.新加 1.正常，2.停用，3:已接单，4：待消毒
        window.map.destroy()
        window.that = this
        window.carNum = data.carNum
        this.car = data
        that.orderDialogFormVisible = false
        that.createMap(that.centerlongitude, that.centerlatitude)
        window.cur = that.cur
        switch (data.onStatus) {
          case '0':
          case '1':
            that.$message.info('车辆空闲中，未获取到车辆派单信息')
            break
          case '2':
            that.$message.error('车辆已停用，请联系平台管理员')
            break
          case '3':
            that.getlocation(0)
            that.circulator()
            break
          case '4':
            that.$message.info('车辆待消毒，未获取到车辆派单信息')
            // that.getlocation(0)
            break
        }
      }
      // if (node.level == 1) {
      //   this.car = data
      //   this.getlocation(0)
      //   window.that = this
      //   that.orderDialogFormVisible = false
      //   this.circulator()
      // }
    },
    loadNode (node, resolve) {
      // 如果展开第一级节点，从后台加载一级节点列表
      if (node.level == 0) {
        this.getProvince(resolve)
      }
      // 如果展开其他级节点，动态从后台加载下一级节点列表
      if (node.level == 1) {
        this.getCity(node, resolve)
      }
      if (node.level == 2) {
        this.getDistrict(node, resolve)
      }
      if (node.level == 3) {
        this.getorderCompany(node, resolve)
      }
      if (node.level == 4) {
        this.getVehicleList(node, resolve)
      }
      if (node.level == 5) {
        resolve([])
      }
    },
    // 获取省份--调试完成
    async getProvince (resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectProvinceList',
        {}
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取省份失败')

      res.data.forEach(item => {
        item.name = item.province
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })

      resolve(res.data)
    },
    // 获取市--调试完成
    async getCity (node, resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectCityList',
        {
          province: node.data.uuid
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取市失败')
      //  console.log(res.data);
      res.data.forEach(item => {
        item.name = item.cityName
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })
      resolve(res.data)
    },
    // 获取区域--调试完成
    async getDistrict (node, resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectDistrictList',
        {
          cityId: node.data.uuid
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取区域失败')

      res.data.forEach(item => {
        item.name = item.disName
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })

      resolve(res.data)
    },
    // 按地区获取机构--调试完成
    async getorderCompany (node, resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/tSysCompany/selectCompany',
        {
          district: node.data.uuid
        }
      )
      // console.log(res.data)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')

      res.data.forEach(item => {
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
        item.name = item.companyName
      })

      resolve(res.data)
    },
    // 按机构获取车辆信息
    async getVehicleList (node, resolve) {
      this.corpId = node.data.uuid
      const { data: res } = await this.$http.post(
        '/pmService/tInfoVehicle/getTInfoVehicleList',
        {
          corpId: node.data.uuid
        }
      )
      if (res.statusCode !== '200') { return this.$message.error('获取车辆信息失败') }
      res.data.forEach(item => {
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
        item.name = item.carNum
      })
      resolve(res.data)
    },
    // 获取轨迹
    async getlocation () {
      const { data: res } = await this.$http.post('/pmService/tInfoVehicle/getTInfoVehicleGps', { carNum: this.car.carNum })
      if (res.statusCode !== '200') return this.$message.info('车辆未启动或GPS信号弱，未获取到车辆位置及相关信息，请联系相关司机')
      // if (res.data.gps = []) return this.$message.info("车辆未启动或GPS信号弱，未获取到车辆位置及相关信息，请联系相关司机")
      if (!res.data.tinfoOrder.orderNo) return this.$message.info('未获取到订单信息，请联系平台管理员')
      // this.carForm = res.data
      console.log(res)
      this.simPhone = res.data.simPhone
      this.direction = res.data.gps[0].direction
      window.fromlongitude = +res.data.gps[0].longitude
      window.fromlatitude = +res.data.gps[0].latitude
      window.tolongitude = +res.data.tinfoOrder.endLongitude
      window.tolatitude = +res.data.tinfoOrder.endLatitude
      // this.getOrderInfo(res.data.tinfoOrder.orderNo) // 获取随车人员信息
      this.gpsForm = res.data.gps[0]
      window.map.setCenter([window.fromlatitude, window.fromlongitude])
      window.initMap(window.fromlatitude, window.fromlongitude, window.tolatitude, window.tolongitude)
    },
    // 获取订单随车人员数据
    async getOrderInfo (orderNo) {
      console.log(orderNo)
      const { data: res } = await this.$http.post('/userManageServer/dispatch/selectOrderPerson', {
        orderNo: orderNo
      })
      res.data.forEach(item => {
        if (item.personType === '0') {
          this.doc.push(item)
        } else if (item.personType === '1') {
          this.nur.push(item)
        } else if (item.personType === '2') {
          this.escort.push(item)
        } else if (item.personType === '3') {
          this.remove.push(item)
        } else {
          this.driver.push(item)
        }
      })
    },
    // 循环器
    circulator () {
      var that = this
      var time = setInterval(function () {
        window.cur++
        that.getlocation()
        // console.log(window.rotate);
        // console.log(window.display_polyline);\
      }, 3000)
      window.time = time
      // setTimeout(() => {
      //   console.log('清除');

      //   clearInterval(time)
      // }, 12000)
    },
    // 创建地图
    createMap (e1, e2) {
      var that = this
      // 定义地图中心点坐标
      var center = new TMap.LatLng(e1, e2)
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      var map = new TMap.Map('mapContainer', {
        center: center, // 设置地图中心点坐标
        zoom: 15, // 设置地图缩放级别
        pitch: 30, // 设置俯仰角
        rotation: 20, // 设置地图旋转角度
        viewMode: '2D', // 设置地图视图模式
        scrollable: false // 是否支持鼠标滚轮缩放地图
      })
      window.map = map
    },
    // 点击预览触发
    preview () {
      this.initPlugin()
      this.jk = true
    },
    tracking () {
      console.log('跟踪')
    },
    // 标记点
    display_mark () {
      // //标记起终点marker
      var marker = new TMap.MultiMarker({
        id: 'marker-layer',
        map: window.map,
        styles: {
          start: new TMap.MarkerStyle({
            width: 44,
            height: 84,
            anchor: { x: 16, y: 32 },
            faceTo: 'map',
            rotate: 0,
            src: require('../../images/carblue.png')
          }),
          end: new TMap.MarkerStyle({
            width: 44,
            height: 84,
            anchor: { x: 16, y: 32 },
            src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png'
          })
        },
        geometries: [{
          id: 'start',
          styleId: 'start',
          position: new TMap.LatLng(window.fromlatitude, window.fromlongitude)
        }, {
          id: 'end',
          styleId: 'end',
          position: new TMap.LatLng(window.tolatitude, window.tolongitude)
        }]
      })
      // //初始化label
      // var label = new TMap.MultiLabel({
      //   id: 'label-layer',
      //   map: window.map, //设置折线图层显示到哪个地图实例中
      //   //文字标记样式
      //   styles: {
      //     'label': new TMap.LabelStyle({
      //       'color': '#000', //颜色属性
      //       'size': 16, //文字大小属性
      //       'offset': { x: 0, y: 0 }, //文字偏移属性单位为像素
      //       'angle': 0, //文字旋转属性
      //       // 'alignment': 'center', //文字水平对齐属性
      //       // 'verticalAlignment': 'middle' //文字垂直对齐属性
      //     })
      //   },
      //   //文字标记数据
      //   geometries: [{
      //     'id': 'label_1', //点图形数据的标志信息
      //     'styleId': 'label', //样式id
      //     'position': new TMap.LatLng(window.fromlatitude, window.fromlongitude), //标注点位置
      //     'content': window.carNum, //标注文本
      //   }]
      // });
      // 初始化infoWindow
      var infoWindow = new TMap.InfoWindow({
        map: window.map,
        position: new TMap.LatLng(window.fromlatitude, window.fromlongitude),
        offset: { x: 0, y: -32 }, // 设置信息窗相对position偏移像素，为了使其显示在Marker的上方
        content: `<h1 style="{ backgroundColor:'#1e1e1e',color:'#ffffff'}">${window.carNum}</h1>` // 设置信息框内容
      })
      // // 监听标注点击事件
      marker.on('click', function (evt) {
        that.phone = evt.simPhone
        that.orderDialogFormVisible = true
      })
      window.infoWindow = infoWindow
      window.marker = marker
      // window.label = label
      window.markerStyle = marker.styles
    },
    // 线路
    display_polyline (pl) {
      // 创建 MultiPolyline显示折线
      var polylineLayer = new TMap.MultiPolyline({
        id: 'polyline', // 图层唯一标识
        map: map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: '#3777FF', // 线填充色
            width: 6, // 折线宽度
            borderWidth: 5, // 边线宽度
            borderColor: '#FFF', // 边线颜色
            lineCap: 'round' // 线端头方式
          })
        },
        // 折线数据定义
        geometries: [
          {
            id: 'pl_1', // 折线唯一标识，删除时使用
            styleId: 'style_blue', // 绑定样式名
            paths: pl
          }
        ]
      })
      window.polylineLayer = polylineLayer
    },
    // 点击刷新页面
    refresh () {
      window.location.reload()
    },
    // 点击安装插件
    install () {
      window.open('')
    },
    // 点击启动插件
    enablement () {
    },
    // 检测用户是否安装插件
    detection (name) {
      // name = name.toLowerCase();
      // for (var i = 0; i < navigator.plugins.length; i++) {
      //   if (navigator.plugins[i].name.toLowerCase().indexOf(name) > -1) {
      //     return true;
      //   }
      // }
      // return false;
      // alert(hasPlugin("Flash"));
      for (var i = 0; i < navigator.plugins.length; i++) {
        console.log(navigator.plugins[i].name)
      }
    },
    // 创建播放实例
    initPlugin () {
      var that = this
      console.log(1)
      // 初始化插件
      this.oWebControl = new WebControl({
        szPluginContainer: 'playWnd',
        iServicePortStart: 14430, // 对应 LocalServiceConfig.xml 中的ServicePortStart值
        iServicePortEnd: 14439, // 对应 LocalServiceConfig.xml 中的ServicePortEnd值
        szClassId: '29DDBC9A-8AEA-4827-9C3A-805D697CF38F',
        cbConnectSuccess: function () {
          that.setCallbacks()

          that.oWebControl.JS_StartService('window', {
            dllPath: './WebPreview.dll'
          }).then(function () {
            that.oWebControl.JS_CreateWnd('playWnd', that.width, that.playHeight).then(function () {
              console.log('JS_CreateWnd success')
              that.setPalywindLayout()

              that.startPlayWindShow()
            })
          }, function () {

          })
        },
        cbConnectError: function () {
          console.log('cbConnectError')
          that.oWebControl = null
          // $("#playWnd").html("确认本地进程是否已安装并开启成功！");
          this.$message.error('请确认本地进程是否已安装并开启成功！')
        },
        cbConnectClose: function (bNormalClose) {
          // 连接异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          console.log('cbConnectClose')
          that.oWebControl = null
        }
      })
    },
    // 设置窗口控制回调
    setCallbacks () {
      var that = this
      console.log('窗口回调成功')
      that.oWebControl.JS_SetWindowControlCallback({
        cbIntegrationCallBack: that.cbIntegrationCallBack
      })
    },
    // 推送消息
    cbIntegrationCallBack (oData) {
      console.log('窗口回调成功消息' + (oData.responseMsg.event))
      var that = this
      if (oData.responseMsg.event == 'startPreviewNotify') {
        console.log('重新获取海康视频地址' + oData.responseMsg.msg.devSerialNum + 'g' + oData.responseMsg.msg.channel + '手机号码' + that.phone)

        var phone = oData.responseMsg.msg.devSerialNum
        var channel = oData.responseMsg.msg.channel

        $.ajax({
          url: '/api/vehicleServer/HkUrl/getHkUrl?phone=' + that.phone + '&channel=' + channel,
          success: function (result) {
            var purl = result
            console.log('重新播放获取海康回调地址' + result)
            if (!purl) {
              alert('设备已关闭！')
              return
            }
            that.oWebControl.JS_RequestInterface({
              funcName: 'startPreview',
              arguments: {
                channel: channel,
                devSerialNum: that.phone,
                url: purl,
                productKey: that.productKey,
                protocol: 0,
                streamType: 1,
                code: 0
              }
            }).then(function (oData) {
              console.log(JSON.stringify(oData.responseMsg))
              // showCBInfo(JSON.stringify(oData.responseMsg));
            })
          }
        })
      }

      // showCBInfo(JSON.stringify(oData.responseMsg));
      /* showCBInfo(JSON.stringify(oData.responseMsg)); */
    },
    // 播放窗口样式设置
    setPalywindLayout () {
      console.log(22222)
      var that = this
      that.oWebControl.JS_RequestInterface({
        funcName: 'setPreviewLayout',
        arguments: {
          channels: [

            {
              channelName: '通道3',
              channel: 3
            },
            {
              channelName: '通道4',
              channel: 4
            }
          ],

          devSerialNum: that.phone,
          productKey: that.appKey,
          layout: 2
        }
      }).then(function (oData) {
        console.log('布局设置成功')
        console.log(JSON.stringify(oData.responseMsg))
      })
    },
    // 设置窗口裁剪，当因滚动条滚动导致窗口需要被遮住的情况下需要JS_CuttingPartWindow部分窗口
    setWndCover () {
      const iWidth = $(window).width()
      const iHeight = $(window).height()
      const oDivRect = $('#playWnd').get(0).getBoundingClientRect()
      let iCoverLeft = (oDivRect.left < 0) ? Math.abs(oDivRect.left) : 0
      let iCoverTop = (oDivRect.top < 0) ? Math.abs(oDivRect.top) : 0
      let iCoverRight = (oDivRect.right - iWidth > 0) ? Math.round(oDivRect.right - iWidth) : 0
      let iCoverBottom = (oDivRect.bottom - iHeight > 0) ? Math.round(oDivRect.bottom - iHeight) : 0

      iCoverLeft = (iCoverLeft > this.width) ? this.width : iCoverLeft
      iCoverTop = (iCoverTop > this.playHeight) ? this.playHeight : iCoverTop
      iCoverRight = (iCoverRight > this.width) ? this.width : iCoverRight
      iCoverBottom = (iCoverBottom > this.playHeight) ? this.playHeight : iCoverBottom

      this.oWebControl.JS_RepairPartWindow(0, 0, (this.width + 1), this.playHeight) // 多1个像素点防止还原后边界缺失一个像素条
      if (iCoverLeft != 0) {
        this.oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, this.playHeight)
      }
      if (iCoverTop != 0) {
        this.oWebControl.JS_CuttingPartWindow(0, 0, (this.width + 1), iCoverTop) // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
      }
      if (iCoverRight != 0) {
        this.oWebControl.JS_CuttingPartWindow(this.width - iCoverRight, 0, iCoverRight, this.playHeight)
      }
      if (iCoverBottom != 0) {
        this.oWebControl.JS_CuttingPartWindow(0, this.playHeight - iCoverBottom, this.width, iCoverBottom)
      }
    },
    startPlayWindShow () {
      var that = this
      var purl
      $.ajax({
        url: `/api/vehicleServer/HkUrl/getHkUrl?phone=${that.phone}&channel=3`,
        success: function (result) {
          purl = result

          if (purl == '') {
            this.$message.info('车辆已关闭，未获取到视频信号')
            return
          }
          that.oWebControl.JS_RequestInterface({
            funcName: 'startPreview',
            arguments: {
              channel: 3,
              devSerialNum: that.phone,
              url: purl,
              productKey: that.appKey,
              protocol: 0,
              streamType: 1,
              code: 0
            }
          }).then(function (oData) {
            // showCBInfo(JSON.stringify(oData.responseMsg));
            console.log('3----------' + JSON.stringify(oData.responseMsg))
          })
        }
      })
      $.ajax({
        url: `/api/vehicleServer/HkUrl/getHkUrl?phone=${that.phone}&channel=4`,
        success: function (result) {
          purl = result

          that.oWebControl.JS_RequestInterface({
            funcName: 'startPreview',
            arguments: {
              channel: 4,
              devSerialNum: that.phone,
              url: purl,
              productKey: that.appKey,
              protocol: 0,
              streamType: 1,
              code: 0
            }
          }).then(function (oData) {
            // showCBInfo(JSON.stringify(oData.responseMsg));
            console.log('4-------' + JSON.stringify(oData.responseMsg))
          })
        }
      })
    },
    // 停止全部预览功能
    stopAllPreview () {
      var that = this
      that.oWebControl.JS_RequestInterface({
        funcName: 'stopAllPreview'
      })
    },
    // 关闭视频窗口
    closeWindow () {
      var that = this
      if (that.oWebControl != null) {
        that.oWebControl.JS_HideWnd() // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
        that.stopAllPreview()
        that.oWebControl.JS_Disconnect().then(
          () => {
            // 断开与插件服务连接成功
          },
          () => {
            // 断开与插件服务连接失败
          }
        )
      }
    },
    closeplayWnd () {
      // console.log('关闭');
      this.closeWindow()
      this.jk = false
    },
    keydown (event) {
      var that = this
      if (event.which === 27) {
        if (that.orderDialogFormVisible = true) {
          that.orderDialogFormVisible = false
        }
      }
      // var e = window.event;
      // var obj = e.srcElement;
      // obj.style.color = 'blue';
    },
    // 车辆数量
    async getCarSum () {
      const { data: res } = await this.$http.post('/pmService/tInfoVehicle/getCarNum', {})
      if (res.statusCode !== '200') return this.$message.error('获取车辆数量信息失败')
      this.carNumForm = res.data
    }

  }
}
</script>
<style lang="less" scoped>
.monitoring {
  width: 100%;
  height: 100%;
  /deep/ .orderDialog {
    position: absolute;
    top: 70%;
    left: 75%;
    transform: translate(-50%, -50%);
    // z-index: 999999 !important;
    width: 400px;
    height: 450px;
    border: 1px solid #63bffe;
    border-radius: 5%;
    /deep/ .el-dialog {
      width: 100%;
      height: 100%;
      margin: 0 !important;
      .el-dialog__header {
        width: 0;
        height: 0;
        display: none;
      }
      .el-dialog__body {
        padding: 0;
        width: 400px;
        height: 450px;
        .el-tabs {
          width: 400px;
          height: 362px;
          display: flex;
          flex-direction: column;
          .el-tabs__header {
            padding: 0 8px;
            .el-tabs__nav-prev,
            .el-icon-arrow-left,
            .el-tabs__nav-next {
              display: none;
              width: 0;
              height: 0;
            }
            .el-tabs__nav-wrap {
              padding: 0;
              .el-tabs__nav {
                width: 100%;
                display: flex;
                .el-tabs__item {
                  flex: 1;
                  padding: 0;
                  text-align: center;
                }
              }
            }
          }
          .el-tabs__content {
            flex: 1;
            padding: 0 20px;
            .el-tab-pane {
              p {
                margin: 15px 0;
                text-align: left;
              }
              .peoInfo {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                p {
                  flex: 1;
                }
              }
            }
            // background-color: pink;
          }
        }
        .btns {
          width: 100%;
          height: 88px;
          display: flex;
          line-height: 88px;
          text-align: center;
          .look,
          .follow {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: #63bffeff;
            color: #fff;
            outline: none;
          }
          .look_icon,
          .follow_icon {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-bottom: 5px;
          }
          .look_icon {
            background: url('../../images/ic_preview.png');
          }
          .follow_icon {
            background: url('../../images/look.png');
          }
        }
      }
    }
  }
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .el-container {
    /deep/.el-tree-node:focus > .el-tree-node__content {
      background-color: #ccc !important;
    }
    width: 100%;
    height: 100%;
    // 左侧树结构
    /deep/ .el-aside {
      // width: 200px;
      width: 400px !important;
      // background-color: pink;
      height: 100%;
      padding: 0 10px;
      padding-top: 10px;

      .aside-title {
        height: 76px;
        background-color: #daf5ff;
        display: flex;
        margin-bottom: 10px;
        .aside-box-one,
        .aside-box-two,
        .aside-box-three {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          :first-child {
            color: #0097fe;
          }
        }
      }
      .search {
        margin-bottom: 10px;
      }
      .tree-title {
        font-size: 20px;
        color: #0097fe;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('../../fonts/ic_second.png') no-repeat;
          background-size: cover;
          margin-right: 5px;
        }
      }
      /deep/ .checkbox {
        background-color: #0097fe;
        height: 30px;
        color: #fff;
        padding-left: 10px;
        line-height: 30px;
        .el-checkbox__label {
          color: #fff;
        }
      }
    }
    // 右侧内容区
    .el-main {
      padding: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      #mapContainer {
        width: 100%;
        height: 100%;
      }
      .Controls {
        width: 100%;
        height: 100%;
        // z-index: 999999;
        background-color: #f4f4f4;
        // text-align: center;
        position: relative;
        .ControlsText {
          // width: 100%;
          // height: 100%;
          position: absolute;
          top: 35%;
          left: 50%;
          transform: translate(-50%, -50%);
          h1 {
            font-size: 24px;
          }
          span {
            display: inline-block;
            color: #848484;
            font-size: 12px;
            margin: 20px 0;
          }
          p {
            font-size: 12px;
            margin-bottom: 20px;
            a {
              color: #76aef6;
              font-size: 12px;
            }
          }
        }
      }
      .playWnd {
        position: absolute;
        top: 0;
        margin-top: 200px;
        left: 400px;
        z-index: 99999;
        float: left;
        width: 440px;
        height: 600px;
        // background-color: pink;
        button {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 0;
          right: 0;
          outline: none;
          border: none;
          background: url('../../images/关 闭.png') no-repeat;
          background-position: center;
        }
      }
    }
  }
}
</style>
